/*  Bilder Slider mit übergang  */
#bilderslide {
  overflow: hidden;
  max-width: 560px;
  border-radius: 6px;
  margin: 1.5rem auto;
  border: 3px solid #0083ff; }

.slider {
  position: relative;
  width: 800%;
  font-size: 0;

  figure {
    width: 12.5%;
    height: auto;
    display: inline-block;
    position: inherit; }

  img {
    width: 100%;
    height: auto; } }

#bilderslide {
  .animate1 {
    animation: bild1 44s linear infinite; }

  .animate2 {
    animation: bild2 44s linear infinite; }

  .animate3 {
    animation: bild3 44s linear infinite; }

  .animate4 {
    animation: bild4 44s linear infinite; }

  .animate5 {
    animation: bild5 44s linear infinite; }

  .animate6 {
    animation: bild6 44s linear infinite; }

  .animate7 {
    animation: bild7 44s linear infinite; }

  .animate8 {
    animation: bild8 44s linear infinite; } }

@keyframes bild1 {
  0% {
    left: 0;
    opacity: 1;
    z-index: 0; }

  9.091% {
    left: 0;
    opacity: 1;
    z-index: 0; }

  11.818% {
    left: 0;
    opacity: 0;
    z-index: -1; }

  96.591% {
    left: 0;
    opacity: 0;
    z-index: -1; }

  100% {
    left: 0;
    opacity: 1;
    z-index: 0; } }

@keyframes bild2 {
  0% {
    left: -12.5%;
    opacity: 0;
    z-index: -1; }

  9.091% {
    left: -12.5%;
    opacity: 0;
    z-index: 0; }

  12.500% {
    left: -12.5%;
    opacity: 1;
    z-index: 0; }

  21.591% {
    left: -12.5%;
    opacity: 1;
    z-index: 0; }

  25% {
    left: -12.5%;
    opacity: 0;
    z-index: -1; }

  100% {
    left: -12.5%;
    opacity: 0;
    z-index: -1; } }

@keyframes bild3 {
  0% {
    left: -25%;
    opacity: 0;
    z-index: -1; }

  21.591% {
    left: -25%;
    opacity: 0;
    z-index: 0; }

  25.000% {
    left: -25%;
    opacity: 1;
    z-index: 0; }

  34.091% {
    left: -25%;
    opacity: 1;
    z-index: 0; }

  37.500% {
    left: -25%;
    opacity: 0;
    z-index: -1; }

  100% {
    left: -25%;
    opacity: 0;
    z-index: -1; } }

@keyframes bild4 {
  0% {
    left: -37.5%;
    opacity: 0;
    z-index: -1; }

  34.091% {
    left: -37.5%;
    opacity: 0;
    z-index: 0; }

  37.500% {
    left: -37.5%;
    opacity: 1;
    z-index: 0; }

  46.591% {
    left: -37.5%;
    opacity: 1;
    z-index: 0; }

  50.000% {
    left: -37.5%;
    opacity: 0;
    z-index: -1; }

  100% {
    left: -37.5%;
    opacity: 0;
    z-index: -1; } }

@keyframes bild5 {
  0% {
    left: -50%;
    opacity: 0;
    z-index: -1; }

  46.591% {
    left: -50%;
    opacity: 0;
    z-index: 0; }

  50.000% {
    left: -50%;
    opacity: 1;
    z-index: 0; }

  59.091% {
    left: -50%;
    opacity: 1;
    z-index: 0; }

  62.500% {
    left: -50%;
    opacity: 0;
    z-index: -1; }

  100% {
    left: -50%;
    opacity: 0;
    z-index: -1; } }

@keyframes bild6 {
  0% {
    left: -62.5%;
    opacity: 0;
    z-index: -1; }

  59.091% {
    left: -62.5%;
    opacity: 0;
    z-index: 0; }

  62.500% {
    left: -62.5%;
    opacity: 1;
    z-index: 0; }

  71.591% {
    left: -62.5%;
    opacity: 1;
    z-index: 0; }

  75.000% {
    left: -62.5%;
    opacity: 0;
    z-index: -1; }

  100% {
    left: -62.5%;
    opacity: 0;
    z-index: -1; } }

@keyframes bild7 {
  0% {
    left: -75%;
    opacity: 0;
    z-index: -1; }

  71.591% {
    left: -75%;
    opacity: 0;
    z-index: 0; }

  75.000% {
    left: -75%;
    opacity: 1;
    z-index: 0; }

  84.091% {
    left: -75%;
    opacity: 1;
    z-index: 0; }

  87.500% {
    left: -75%;
    opacity: 0;
    z-index: -1; }

  100% {
    left: -75%;
    opacity: 0;
    z-index: -1; } }

@keyframes bild8 {
  0% {
    left: -87.5%;
    opacity: 0;
    z-index: -1; }

  84.091% {
    left: -87.5%;
    opacity: 0;
    z-index: 0; }

  87.500% {
    left: -87.5%;
    opacity: 1;
    z-index: 0; }

  96.591% {
    left: -87.5%;
    opacity: 1;
    z-index: 0; }

  100% {
    left: -87.5%;
    opacity: 0;
    z-index: 0; } }
